.modal-background {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9000;
}

.modal {
  position: fixed;
  z-index: 9100;
  background-color: white;
  width: 80%;
  border: 1px solid #333;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  left: 10%;
  top: 20%;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  display: flex;
  flex-direction: column;
  min-height: 20.5rem;
}

.modal-full-height {
  bottom:20%;
}

@media (min-width: 600px) {
  .modal {
      width: 540px;
      left: calc(50% - 270px); /* 270px is half of the 540px width */
  }
}

.modal .close {
  cursor: pointer;
  background: white;
  color: black;
	position: absolute;
	right: -12px;
	text-align: center;
	top: -10px;
  font-size: 1.3rem;
	width: 1.5rem;
  padding: 0.04rem 0 0;
  margin: 0;
	text-decoration: none;
	-webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border: none;
	border-radius: 12px;
}

.modal h2 {
  margin: 0.5em 0;
}

.modal input {
  font-size: 1.1em;
  padding: .2em;

  display: block;
  /* border-box: account for any border and padding in the width/height values */
  box-sizing: border-box;
  width: 98%;
  margin-top: .3em;
  margin-left: 2%;
}

.modal textarea {
  font-family: monospace;
  font-size: 0.9rem;
}

.modal textarea.paste, textarea.export {
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  flex: 1;
  resize: none;
}

.modal .paste-buttons, .export-buttons {
  margin-top: 0.5em;
}

.modal-export label.export-set-switch,
.modal-export label.export-hidden-switch,
.modal-paste label.paste-update-switch {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 1em;
}

.modal-custom-ratings {
  bottom: initial;
}

.modal-custom-ratings div {
  margin: 1em 0;
}

.modal-about {
  font-size: 0.9rem;
  bottom: initial;
}

.modal-about p {
  margin: 0.5em 1rem
}

.modal-about div.support {
  margin: 0 auto;
  font-size: 1.1rem;
}

.modal-about div.support .patreon-button,
.modal-about div.support .kofi-button {
  display: inline-block;
  margin: 0 0.6em;
}

.modal-about div.support .patreon-button a,
.modal-about div.support .kofi-button a {
  /* Make the support buttons a little thicker */
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.modal-about p.wotc-fan-content {
  font-size: 0.76rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
}

.modal textarea {
  font-family: monospace;
  font-size: 0.9rem;
}

.modal-export label.export-set-switch,
.modal-export label.export-hidden-switch,
.modal-paste label.paste-update-switch {
  display: inline-block;
  vertical-align: text-bottom;
  margin-left: 1em;
}

.modal-export-tierlist {
  /* override the default .modal min-height for short content */
  min-height: initial;
}

.modal-export-tierlist div {
  margin: .4em 0;
}

.export-seventeenlands-update input {
  font-size: 1.1em;
  padding: .2em;

  display: block;
  /* border-box: account for any border and padding in the width/height values */
  box-sizing: border-box;
  width: 98%;
  margin-top: .3em;
  margin-left: 2%;
}

.export-seventeenlands-create button, 
.export-seventeenlands-update button {
  margin-left: 2%;
}
