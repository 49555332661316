.dropdown-button {
  /* This position/display combination allows the height of 
     .dropdown-button-arrow to expand to match the content 
     within .dropdown-button-button   */
  position: relative;
  display: inline-flex;
  /* vertical-align: middle; */
}

.dropdown-button-button {
  margin-right: 0;
  border-right: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.dropdown-button-arrow {
  margin-left: 0;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-button-arrow.expanded {
  background-color: var(--blue);
}

.dropdown-button-items {
  position: absolute;
  left: 0;
  /* match left margin with standard button margin-left */
  margin-left: 0.2em;

  border: 1px solid #999;
  border-radius: 0.25em;
  /* padding at least the size of the border-radius so that
     each individual .dropdown-button-item doesn't overflow */
  padding: 0.25em 0;
  background-color: white;
  color: black;
}

button.dropdown-button-item {
  cursor: pointer;
  text-align: left;

  display: block;
  width: 100%;

  padding: 0.3em 0.5em;
  margin: 0;
  border: none;
  border-radius: 0;
}

button.dropdown-button-item:hover {
}