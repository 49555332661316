input.card-text-filter {
  width: 94%;
  font-size: 1em;
  padding: 0.1em;
}

.card-text-filter-types {
  padding-top: 0.2em;
  /* display: inline-grid; */
}

.card-text-filter-types label {
  padding: 0.1em;
}

.card-text-filter-types.hidden {
  display: none;
}
