.labelswitch {
  vertical-align: middle;
  padding: 0 .2em .125em;
}

.labelswitch-disabled {
  color: #666;
}

/***************************************************************/
/* START of Switch styling overrides for react-switch library  */
/* https://github.com/markusenglund/react-switch               */

/* Override hard-coded react-switch styles with em */
.react-switch-bg {
  height: 1.125em !important;
  width: 1.75em !important;
}
.react-switch-handle {
  height: 1em !important;
  width: 1em !important;
}
/* END of Switch styling overrides for react-switch library    */
/***************************************************************/
