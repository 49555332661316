/* Set Input Group styling */
.set-input-group {
  margin: 0.3em 0.6em 0.6em;
}

/* Styling for the react-select widget */
.set-input-select {
  width: 10em;
  margin: 0 auto;
}
/* shrink the height of the react-select widget to match our other buttons */
.set-input-select__control {
  /* shrink height */
  min-height: auto !important;
}
.set-input-select__value-container {
  /* shrink height */
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}
.set-input-select__dropdown-indicator {
  /* shrink height */
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.set-input-group .set-input-select {
  /* reset select box text alignment; don't inherit */
  text-align: initial;
}
/* style the border and cursor */
.set-input-select__control {
  border-color: #333 !important;
  cursor: pointer !important;
}
.set-input-select__option {
  cursor: pointer !important;
}
/* style the dropdown indicator arrow on hover */
.set-input-select__control:hover .set-input-select__indicators {
  background-color: var(--blue-hover);
}
/* style the dropdown indicator arrow when open */
.set-input-select__control--menu-is-open .set-input-select__indicators {
    background-color: var(--blue);
}
.set-input-select__control--menu-is-open .set-input-select__indicators {
  background-color: var(--blue);
}
.set-input-select__control:hover .set-input-select__indicator-separator,
.set-input-select__control--menu-is-open .set-input-select__indicator-separator {
  /* hide the separator when highlighting the background (above) */
  visibility: hidden;
}
/* set the indicator arrow to always stay black */
.set-input-select__indicator {
  color: #333 !important;
}
.set-input-select__indicator:hover {
  color: #333;
}
/* style the dropdown menu options */
.set-input-select__option--is-focused {
  background-color: var(--blue-hover) !important;
}
.set-input-select__option--is-selected {
  background-color: var(--blue) !important;
}
.set-input-select__menu-portal {
  /* change the z-index of the react-select dropdown menu:
      higher than the .pool-header and .Resizer z-index (3500)
     (see https://stackoverflow.com/a/63898744/186818) */
  /* !important to override the default MenuPortal z-index (1) */
  z-index: 3600 !important;
}
.set-input-select__menu {
  /* position the menu directly under the select box (no gap) */
  margin-top: 0 !important;
  /* set the react-select menu width equal to the longest option
     (see https://github.com/JedWatson/react-select/issues/4201#issuecomment-874098561) */
  min-width: 100%;
  /* !important to override the default menu width (100%) */
  width: max-content !important;
}
.set-input-select__menu-list {
  /* always show vertical scrollbar so that the scrollbar width is included in
     the max-content width for the menu (above) */
  /* !important to override the default overflow-y (auto) */
  overflow-y: scroll !important;
}
/* style the select control when disabled to match button:disbled */
.set-input-select__control--is-disabled {
  opacity: 0.5;
  background-color: #999 !important;
}
.set-input-select__control--is-disabled .set-input-select__placeholder {
  color: #111 !important;
}
.set-input-select__control--is-disabled .set-input-select__indicator-separator {
  background-color: #333;
}

/* Set Input Action Control Styling */
.set-input-actions {
  margin-top: 0.2em;
}
