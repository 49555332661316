a.patreon-button {
  background-color:#FF424D;
  color:#fff;

  border: 0 none;
  padding-top: 0.2em;
  /* Add a _little_ extra padding below the text within buttons;
   * Particularly to fix noticeable offset with Windows (?) font */
  padding-bottom: 0.24em;
  padding-right: 0.5em;
  margin: 0.2em 0;

  height: 1.3em;
  max-width: 10em;
}
a.patreon-button:hover {
  background-color:#FF424D;
  opacity: 0.85;
}

a.patreon-button img.patreonimg {
  vertical-align: middle;
  padding: 0 0.2em 0.1em;
  width: 1em;
}
