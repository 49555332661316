a.kofi-button {
  background-color:#29abe0;
  color:#fff;
  font-family:'Quicksand',Helvetica,Century Gothic,sans-serif;
  font-weight:600;

  border: 0 none;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-right: 0.5em;
  margin: 0.2em 0;

  height: 1.3em;
  max-width: 10em;
}
a.kofi-button:hover {
  background-color:#29abe0;
  opacity: 0.85;
}

span.kofitext {
  letter-spacing: -0.15px;
}

a.kofi-button img.kofiimg {
  vertical-align: middle;
  padding: 0 0.1em 0.1em;
  width: 1.6em;
}
