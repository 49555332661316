a.button-link {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

span.button-link.disabled {
  opacity: 0.5;
  color: #111;
  background-color: #999;
  cursor: default;
}